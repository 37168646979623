<!-- 자료실 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6 bg-success">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0 title">법사님 질문있어요</h3>
                </div>
              </div>
            </div>
            <modal v-model:show="showModal" size="lg" body-classes="p-0">
              <template v-slot:header>
                <h6 class="modal-title">법사님께 질문하기 수정</h6>
              </template>
              <card type="secondary" body-classes="px-lg-5">
                <form role="form">
                  <base-input required>
                    <textarea
                      v-model="postQuestion"
                      cols="100"
                      rows="12"
                      class="form-control"
                    />
                  </base-input>

                  <div class="text-center">
                    <base-button type="primary" class="my-4" @click="onSave"
                      >수정 하기</base-button
                    >
                    <base-button
                      type="secondary"
                      class="my-4"
                      @click="showModal = false"
                      >닫 기</base-button
                    >
                  </div>
                </form>
              </card>
            </modal>
            <div class="card-body">
              <div>
                <br />
                <p>
                  1. 질문은 지금까지 배운 법문(강의)에 대해 궁금한 점,
                  수행,정토회에 관련한 내용으로 합니다.
                </p>

                <p>
                  2. 개설된 사이트 외에는 개인적인 메시지나 SNS 등으로 질문하지
                  않습니다.
                </p>

                <p>
                  3. 질문은 일주일에 2개 이내로 할 수 있습니다. 답변은
                  일주일내로 받을 수 있습니다만, 상황에 따라 조금 늦어질 수도
                  있습니다.
                </p>
                <p>4. 지식과 관련한 질문은 개인적으로 찾아봅니다.</p>
              </div>

              <div id="posts" class="mt-4"><h3>질문하기</h3></div>
              <div>
                <form role="form">
                  <base-input class="px-0 mx-0">
                    <textarea
                      v-model="question2"
                      class="form-control"
                      id="exampleFormControlTextarea3"
                      style="width: 100%"
                      rows="7"
                      placeholder="질문을 이곳에 작성해주세요.."
                    ></textarea>
                  </base-input>
                </form>
              </div>
              <div class="col-sm-4 text-center" v-if="verboseStudent">
                <base-button type="primary" @click="question()">
                  <i class="ni ni-send"></i> 보내기
                </base-button>
              </div>
              <div class="card-body px-0">
                <table align="center" width="100%">
                  <ul class="list-group list-group-flush list">
                    <!-- style="border:1px solid black;border-radius:10px/10px" -->

                    <li
                      v-for="row in tableData"
                      :key="row.id"
                      class="list-group px-0"
                    >
                      <td class="list-group-item px-2">
                        <H3
                          ><div
                            class="mx-3"
                            v-html="row.name"
                            style="float: left"
                          ></div
                        ></H3>
                        <div
                          class="mr-3"
                          style="float: right; display: inline-block"
                        >
                          <base-button
                            size="sm"
                            type="primary"
                            @click="goEdit({ row })"
                            v-if="!row.postAnswer"
                          >
                            수정
                          </base-button>
                        </div>
                        <div
                          class="mr-3"
                          v-html="row.createdTime"
                          style="float: right; display: inline-block"
                        ></div>
                      </td>
                      <td class="list-group-item px-2">
                        <div class="mx-3" v-html="row.postQuestion"></div>
                      </td>
                      <td class="list-group-item px-2" v-if="row.postAnswer">
                        <H3
                          ><div
                            class="mx-3"
                            v-html="row.mentor"
                            style="float: left"
                          ></div
                        ></H3>
                        <div
                          class="mr-3"
                          v-html="row.lastUpdated"
                          style="float: right; display: inline-block"
                        ></div>
                      </td>
                      <td class="list-group-item px-2" v-if="row.postAnswer">
                        <div class="mx-3" v-html="row.postAnswer"></div>
                      </td>

                      <br />
                    </li>
                  </ul>
                </table>
              </div>

              <!-- Pagination -->
              <div class="card-footer py-4 d-flex justify-content-center">
                <common-pagination
                  @page-change="getDataFromApi"
                  :rows-per-page="perPage"
                  :total="total"
                />
              </div>
              <!-- End Pagination -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as api from "@/api";
import moment from "moment";
import Modal from "@/components/Modal";
import { Auth } from "@/api";
import { ref } from "vue";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
    Modal,
  },
  emits: ["editDone"],
  // 데이터 객체 추가
  setup(props, { emit }) {
    const tableData = ref([]);
    const perPage = ref(20); // 페이지당 레코드 수
    const total = ref(0); // 총 레코드 수
    const question2 = ref("");
    const postQuestion = ref("");
    const tableToggle = ref(true);
    const show = ref(false);
    const showModal = ref(false);
    const post = ref("");
    const verboseStudent = ref(false);
    const mentor = ref("");
    const id = ref("");

    const created = () => {
      const token = Auth.tokenContent;
      verboseStudent.value = token.role.includes("student");
    };
    created();

    // api 호출
    const getDataFromApi = (page = 1) => {
      const token = Auth.tokenContent;
      let local = token.local;
      let room = token.room;
      let team = token.team;

      const url2 = `service/class2/room?page=${page}&local=${local}&room=${room}&grade=${token.grade}&year=${token.year}&semester=${token.semester}`;
      api.get(url2).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        if (data.rows > 0) {
          let mentor2 = d[0].mentor.trim();
          if (mentor2 || mentor2 != "") {
            mentor.value = d[0].mentor;
          } else {
            const url2 = `service/class2/team?page=${page}&local=${local}&room=${room}&team=${team}&grade=${token.grade}&year=${token.year}&semester=${token.semester}`;
            api.get(url2).then((response) => {
              if (!api.isSuccess(response)) {
                return;
              }
              const data = response.data;
              const d = data.data;
              if (data.rows > 0) {
                mentor.value = d[0].mentor;
              }
            });
          }
        }
      });
      const url = `service/class2/question?page=${page}&memberNo=${token.memberNo}&grade=${token.grade}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        if (data.rows > 0) {
          for (const row of d) {
            row.createdTime = moment(row.createdTime).format(
              "YYYY-MM-DD HH:mm"
            );
            row.lastUpdated = moment(row.lastUpdated).format(
              "YYYY-MM-DD HH:mm"
            );
            show.value = true;
            if (row.postQuestion) {
              row.postQuestion = getContent(row.postQuestion);
            }
            if (row.postAnswer) {
              row.postAnswer = getContent(row.postAnswer);
            }
          }
        }
        tableData.value = d;
        total.value = data.total;
      });
    };
    getDataFromApi();

    const getContent = (content) => {
      return content.split("\n").join("<br>");
    };
    const toggle = () => {
      tableToggle.value = !tableToggle.value;
    };
    const question = () => {
      const token = Auth.tokenContent;
      const data = {};
      data.grade = token.grade;
      data.year = token.year;
      data.semester = token.semester;
      data.memberNo = token.memberNo;
      data.local = token.local;
      data.room = token.room;
      data.team = token.team;
      data.name = token.name;
      data.semesterCode = token.semesterCode;
      data.email = token.email;
      question2.value = question2.value.trim();
      if (question2.value && question2.value !== "") {
        data.postQuestion = question2.value;
        data.mentor = mentor.value;
        if (confirm("법사님께 질문을 등록하시겠습니까?")) {
          api.post("service/class2/question", { data }).then(() => {
            question2.value = "";
            getDataFromApi();
          });
        }
      } else {
        alert("글을 등록하세요!!");
      }
    };

    // 수정하기
    const onSave = (row) => {
      const data = {};
      data.postQuestion = postQuestion.value;
      api.put(`service/class2/question/${id.value}`, { data }).then(() => {
        emit("editDone", row, data);
        getDataFromApi();
        showModal.value = false;
      });
    };
    const goEdit = (row) => {
      id.value = row.row.id;
      postQuestion.value = row.row.postQuestion.replace(/(<br>)/g, "\n");
      showModal.value = true;
    };
    // const goDelete = (row) => {
    //   const data = {};
    //   data.state = -9;
    //   if (confirm("법사님께 질문을 삭제하시겠습니까?")) {
    //     api.put(`service/class2/question/${row.row.id}`, { data }).then(() => {
    //       this.tableData = this.tableData.filter((r) => r.id !== row.row.id);
    //     });
    //   }
    // };
    return {
      tableData, // 레코드셋
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      question2,
      postQuestion,
      tableToggle,
      show,
      showModal,
      post,
      verboseStudent,
      mentor,
      goEdit,
      onSave,
      question,
      toggle,
    };
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
</style>
