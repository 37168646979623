<!-- 메인 페이지 -->
<template>
  <div class="white2">
    <div style="padding-top: 10px; height: auto">
      <div style="margin-left: 1em" v-if="name">
        <h2 class="mb-2 mt-0">
          <span id="welcome"
            ><strong>{{ name }}</strong
            >님<br />
            정토{{ grade }}에</span
          ><br />
          <span>오신것을 환영합니다.</span>
        </h2>
      </div>
      <div id="box">
        <div>
          <div style="margin-left: 2%">
            <h2 class="mb-0">온라인 수업과정</h2>
          </div>
          <a href="#" @click.prevent="selectMenu((id = 1))">
            <img class="wide" :src="require('@/assets/법문듣기.svg')" />
          </a>
          <a href="#" @click.prevent="selectMenu((id = 2))">
            <img
              class="narrow"
              :src="require('@/assets/도반과 온라인 수업.svg')"
            />
          </a>
        </div>
        <div>
          <a href="#" @click.prevent="selectMenu((id = 3))">
            <img class="wide" :src="require('@/assets/일상에서수행.svg')" />
          </a>
          <a href="#" @click.prevent="selectMenu((id = 7))">
            <img class="narrow" :src="require('@/assets/법사님 질문.svg')" />
          </a>
        </div>
        <div>
          <a href="#" @click.prevent="selectMenu((id = 4))">
            <img class="wide" :src="require('@/assets/수업자료실.svg')" />
          </a>
          <a href="#" @click.prevent="selectMenu((id = 5))">
            <img class="narrow" :src="require('@/assets/공지사항.svg')" />
          </a>
        </div>
        <div>
          <a href="#" @click.prevent="selectMenu((id = 6))">
            <img class="wide" :src="require('@/assets/실천활동자료실.svg')" />
          </a>
          <!-- 즉문즉설 이동수업지침에 따라 수기 진행으로 메뉴 하이드시킴 -->
          <!-- <a href="#" @click.prevent="selectMenu((id = 8))">
            <img class="narrow" :src="require('@/assets/즉문즉설.svg')" />
          </a> -->
        </div>
        <div>
          <a href="#" @click.prevent="selectMenu((id = 14))">
            <img class="wide" :src="require('@/assets/천일결사 자료실.svg')" />
          </a>
          <!-- 학사팀 요청으로 줌 입장하기 메뉴 숨김 -->
          <!-- <a href="#" @click.prevent="selectMenu((id = 13))">
            <img class="narrow" :src="require('@/assets/zoom2.svg')" />
          </a> -->
        </div>
      </div>
      <div class="card mt-3 ml-2 mr-2">
        <div class="card-body">
          <h3 class="mb-0">학사 관리</h3>
          <br />
          <ul class="list-group">
            <li class="list-group-item">
              <a class="nav-link" href="#" @click.prevent="selectMenu((id = 9))"
                ><i class="ni ni-hat-3"></i>
                <span class="nav-link-text">출석부</span></a
              >
            </li>
            <li class="list-group-item">
              <a
                class="nav-link"
                href="#"
                @click.prevent="selectMenu((id = 10))"
                ><i class="ni ni-hat-3 text-pink"></i>
                <span class="nav-link-text">학사일정</span></a
              >
            </li>
            <li class="list-group-item">
              <a
                class="nav-link"
                href="#"
                @click.prevent="selectMenu((id = 11))"
                ><i
                  class="ni ni-single-02"
                  style="color: #5b4283 !important"
                ></i>
                <span class="nav-link-text">내정보</span></a
              >
            </li>
            <li class="list-group-item">
              <a
                class="nav-link"
                href="#"
                @click.prevent="selectMenu((id = 12))"
                ><i class="ni ni-user-run text-gray"></i>
                <span class="nav-link-text">로그아웃</span></a
              >
            </li>
          </ul>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
import { Auth } from "@/api";
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  setup() {
    const name = ref(""); // 학생이름
    const grade = ref(""); // 대학구분
    const buldae = ref(false);
    const router = useRouter();

    const getDataFromApi = () => {
      const token = Auth.tokenContent;
      name.value = sessionStorage.getItem("name");
      grade.value = token.grade;
      if (grade.value === "불교대학") {
        buldae.value = true;
      }
      let a = window.innerWidth;
      let b = window.innerHeight;
      if (a > b) {
        router.push({
          name: "홈",
        });
      }
    };
    getDataFromApi();

    const selectMenu = (id) => {
      if (id === 1) {
        router.push({
          name: "법문듣기",
        });
      } else if (id === 2) {
        router.push({
          name: "도반과 온라인 수업",
        });
      } else if (id === 3) {
        router.push({
          name: "일상에서 수행연습",
        });
      } else if (id === 4) {
        router.push({
          name: "수업자료실",
        });
      } else if (id === 5) {
        router.push({
          name: "공지사항",
        });
      } else if (id === 6) {
        router.push({
          name: "실천활동 자료실",
        });
      } else if (id === 7) {
        router.push({
          name: "법사님 질문있어요",
        });
      } else if (id === 8) {
        router.push({
          name: "법륜스님 즉문즉설",
        });
      } else if (id === 9) {
        router.push({
          name: "출석부",
        });
      } else if (id === 10) {
        router.push({
          name: "학사일정",
        });
      } else if (id === 11) {
        router.push({
          name: "내정보",
        });
      } else if (id === 12) {
        Auth.logout(router);
      } else if (id === 13) {
        router.push({
          name: "줌 참가 안내",
        });
      } else if (id === 14) {
        router.push({
          name: "천일결사 자료실",
        });
      }
    };
    return {
      name,
      grade,
      buldae,
      selectMenu,
    };
  },
};
</script>
<style>
#box {
  box-shadow: 5px 5px 5px rgb(194, 184, 184);
  padding: 0 0 0 0.5%;
  margin: 10px 0.1em 1em 0.3em;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 3%;
}
strong {
  color: blue;
}
.wide {
  width: 63%;
  height: 91px;
  left: 0px;
  top: 0px;
  margin-left: 2px;
  margin-bottom: 10px;
}
.narrow {
  width: 31%;
  height: 90px;
  left: 212px;
  top: 0px;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 10px;
}
.topline {
  width: 100%;
  height: 45px;
  background-color: #f2651c;
}
.topmark {
  top: 10px;
  left: 20px;
  position: absolute;
}
.white2 {
  background-color: white;
}
</style>
