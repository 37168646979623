export const ROOM_NUMBER = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
export const TEAM_NUMBER = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
];

// 지역명 - 불교대학용
export const LOCAL_NAMES_B = [
  "전체",
  "서울제주",
  "강원경기동부",
  "인천경기서부",
  "대전충청",
  "광주전라",
  "대구경북",
  "부산울산",
  "경남",
  "해외",
];

// 지역명 - 경전반용
export const LOCAL_NAMES_K = [
  "경주",
  "광주",
  "김해",
  "남양주",
  "노원",
  "달서",
  "대전",
  "동래",
  "부천",
  "북미동부",
  "북미서부",
  "분당",
  "사하",
  "서대문",
  "서초",
  "송파",
  "수성",
  "수원",
  "아시아태평양",
  "안양",
  "양천",
  "용인",
  "울산",
  "원주",
  "유럽",
  "인천",
  "일산",
  "제주",
  "전주",
  "진주",
  "창원",
  "천안",
  "청년",
  "청주",
  "해운대",
];

export const ROLE_TYPES = {
  "class leader": "운영자",
  "team leader": "진행자",
  "team assist": "돕는이",
  "head leader": "진행자(사이트관리)",
  staff: "돕는이",
  "head staff": "돕는이(사이트관리)",
  mentor: "법사",
  observer: "참관자",
  manager: "관리자",
};

const SENIOR_SEMESTER = "K027";

export function getLocalNames(semester) {
  if (semester == SENIOR_SEMESTER) {
    return LOCAL_NAMES_K;
  } else {
    return LOCAL_NAMES_B;
  }
}

export const BLESSON_NUMBER = {
  online: 19,
  talk: 3,
  offline: 4,
};

export const KLESSON_NUMBER = {
  online: 19,
  talk: 3,
  offline: 4,
};

export const BSECTION = [
  "전체",
  "입학식",
  "실천적불교사상",
  "인간붓다",
  "수행맛보기/불교와사회",
];
export const KSECTION = [
  "전체",
  "입학식",
  "대승불교/금강경",
  "반야심경/법화경/화엄경",
  "육조단경/한국불교/세계불교",
];
