<!-- 일상에서 수행연습 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6 bg-success">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0 title">일상에서 수행연습 나누기</h3>
                </div>
              </div>
            </div>
            <modal v-model:show="showModal" size="lg" body-classes="p-0">
              <template v-slot:header>
                <h6 class="modal-title">나누기 수정</h6>
              </template>
              <card type="secondary" body-classes="px-lg-5">
                <form role="form">
                  <QuillEditor
                    id="editor"
                    v-model:content="post"
                    content-type="html"
                    theme="snow"
                    :toolbar="[
                      'bold',
                      'italic',
                      'underline',
                      { header: [1, 2, 3, 4, 5, 6, false] },
                      {
                        color: [
                          'black',
                          'white',
                          'red',
                          'blue',
                          'yellow',
                          'green',
                          'violet',
                          'cyan',
                          'brown',
                          'tomato',
                          'salmon',
                          'chocolate',
                          'gold',
                          'lime',
                          'purple',
                          'gray',
                        ],
                      },
                      {
                        background: [
                          'black',
                          'white',
                          'red',
                          'blue',
                          'yellow',
                          'green',
                          'violet',
                          'cyan',
                          'brown',
                          'tomato',
                          'salmon',
                          'chocolate',
                          'gold',
                          'lime',
                          'purple',
                          'gray',
                        ],
                      },
                      'align',
                      { font: [] },
                      // 'image', //이미지 업로드 기능 제외
                      'link',
                      'video',
                      'clean',
                    ]"
                    :modules="modules"
                  />
                  <div class="text-center">
                    <base-button type="primary" class="my-4" @click="onSave"
                      >수정 하기</base-button
                    >
                    <base-button
                      type="secondary"
                      class="my-4"
                      @click="showModal = false"
                      >닫 기</base-button
                    >
                  </div>
                </form>
              </card>
            </modal>
            <div class="card-body text-center mx-0">
              <table align="center" width="100%">
                <td class="list-group-item px-2">
                  <p class="text-left">
                    {{ start1 }}월 {{ start2 }}일 ~ {{ end1 }}월 {{ end2 }}일
                  </p>
                  <hr />
                  <!-- <div class="row ml-2">
                    <img :src="fileName" />
                  </div> -->
                  <hr />
                  <p class="text-left" v-html="content"></p>
                  <div class="text-center mt-4"><br /><br /></div>
                </td>
                <br />
              </table>
              <div>
                <form role="form">
                  <QuillEditor
                    id="editor"
                    v-model:content="share"
                    content-type="html"
                    theme="snow"
                    :toolbar="[
                      'bold',
                      'italic',
                      'underline',
                      { header: [1, 2, 3, 4, 5, 6, false] },
                      {
                        color: [
                          'black',
                          'white',
                          'red',
                          'blue',
                          'yellow',
                          'green',
                          'violet',
                          'cyan',
                          'brown',
                          'tomato',
                          'salmon',
                          'chocolate',
                          'gold',
                          'lime',
                          'purple',
                          'gray',
                        ],
                      },
                      {
                        background: [
                          'black',
                          'white',
                          'red',
                          'blue',
                          'yellow',
                          'green',
                          'violet',
                          'cyan',
                          'brown',
                          'tomato',
                          'salmon',
                          'chocolate',
                          'gold',
                          'lime',
                          'purple',
                          'gray',
                        ],
                      },
                      'align',
                      { font: [] },
                      // 'image', //이미지 업로드 기능 제외
                      'link',
                      'video',
                      'clean',
                    ]"
                    :modules="modules"
                  />
                </form>
              </div>
              <div class="mb-3 mt-2">
                <base-button type="primary" @click="goShare({ id })">
                  <i class="ni ni-send"></i> 나누기 쓰기
                </base-button>
              </div>

              <div class="card-body mx-0 px-0">
                <table align="center" width="100%">
                  <ul class="list-group list-group-flush list my--3">
                    <li
                      v-for="row in tableData"
                      :key="row.id"
                      class="list-group px-0"
                    >
                      <td class="list-group-item px-2">
                        <H3
                          ><div
                            class="ml-1"
                            v-html="row.no + '. ' + row.name"
                            style="float: left"
                          ></div
                        ></H3>
                        <div
                          class="mr-3"
                          style="float: right; display: inline-block"
                          v-if="row.showButton"
                        >
                          <base-button
                            size="sm"
                            type="primary"
                            @click="goDelete({ row })"
                          >
                            삭제
                          </base-button>
                        </div>
                        <div
                          class="mr-3"
                          style="float: right; display: inline-block"
                          v-if="row.showButton"
                        >
                          <base-button
                            size="sm"
                            type="primary"
                            @click="goEdit({ row })"
                          >
                            수정
                          </base-button>
                        </div>

                        <div
                          class="mr-3"
                          style="float: right; display: inline-block"
                          v-html="row.createdTime"
                        ></div>
                      </td>
                      <td class="list-group-item px-2" align="left">
                        <div class="mx-3" v-html="row.post"></div>
                      </td>

                      <br />
                    </li>
                  </ul>
                </table>
              </div>
              <!-- Pagination -->
              <div class="card-footer py-4 d-flex justify-content-center">
                <common-pagination
                  @page-change="getDataFromApi"
                  :rows-per-page="perPage"
                  :model-value="page2"
                  :total="total"
                />
              </div>
              <!-- End Pagination -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as api from "@/api";
import moment from "moment";
import Modal from "@/components/Modal";
import { Auth } from "@/api";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import ImageUploader from "quill-image-uploader";
import axios from "axios";
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
    Modal,
    QuillEditor,
  },
  // 데이터 객체 추가
  emits: ["addDone", "editDone"],
  setup(props, { emit }) {
    const tableData = ref([]); // 레코드셋
    const perPage = ref(20); // 페이지당 레코드 수
    const page2 = ref(1);
    const total = ref(0); // 총 레코드 수
    const start1 = ref(0);
    const start2 = ref(0);
    const end1 = ref(0);
    const end2 = ref(0);
    const post = ref("");
    const id = ref("");
    const share = ref("");
    const fileName = ref("");
    const showModal = ref(false);
    const content = ref("");
    const modules = ref({
      name: "imageUploader",
      module: ImageUploader,
      options: {
        upload: (file) => {
          return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("filelist", file);
            axios
              .post(`${process.env.VUE_APP_API_BASE_URL}/imagesave`, formData)
              .then((res) => {
                resolve(res.data.url);
              })
              .catch((err) => {
                reject("Upload failed");
                console.error("Error:", err);
              });
          });
        },
      },
    });
    const router = useRouter();

    // api 호출
    const getDataFromApi = (page = 1) => {
      page2.value = page;
      const token = Auth.tokenContent;
      const url = `service/class2/practiceRetro/${router.currentRoute._rawValue.query.suid}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const d = response.data.data;
        start1.value = moment(d.start).format("M");
        start2.value = moment(d.start).format("D");
        end1.value = moment(d.end).format("M");
        end2.value = moment(d.end).format("D");
        content.value = getContent(d.post);
        fileName.value = d.fileName;
        id.value = d.id;
      });
      const url2 = `service/class2/sharePractice?page=${page}&shareId=${router.currentRoute._rawValue.query.suid}&grade=${token.grade}&semester=${token.semester}&year=${token.year}&local=${token.local}&room=${token.room}&team=${token.team}`;
      api.get(url2).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        moment.locale("ko");
        if (data.rows > 0) {
          for (const row of d) {
            row.createdTime = moment(row.createdTime).format(
              "YYYY년 MMMM Do HH시mm분"
            );
            if (sessionStorage.getItem("email") == row.email) {
              row.showButton = true;
            } else {
              row.showButton = false;
            }
            row.post = getContent(row.post);
          }
        }
        tableData.value = d;
        total.value = data.total;
      });
    };
    getDataFromApi(1);
    const getContent = (content) => {
      return content.split("\n").join("<br>");
    };
    // 수정하기
    const onSave = (row) => {
      const data = {};
      data.post = post.value;
      api.put(`service/class2/sharePractice/${id.value}`, { data }).then(() => {
        emit("editDone", row, data);
        getDataFromApi();
        showModal.value = false;
      });
    };
    const goEdit = (row) => {
      id.value = row.row.id;
      post.value = row.row.post.replace(/(<br>)/g, "\n");
      showModal.value = true;
    };

    const goShare = (row) => {
      const token = Auth.tokenContent;
      const data = {};
      data.shareId = row.id;
      data.grade = token.grade;
      data.year = token.year;
      data.semester = token.semester;
      data.memberNo = token.memberNo;
      data.local = token.local;
      data.room = token.room;
      data.team = token.team;
      data.name = token.name;
      data.semesterCode = token.semesterCode;
      data.email = token.email;

      if (share.value) {
        data.post = share.value;
        if (confirm("나누기를 등록하시겠습니까?")) {
          api.post("service/class2/sharePractice", { data }).then(() => {
            share.value = " ";
            getDataFromApi();
          });
        }
      } else {
        alert("글을 등록하세요!!");
      }
    };
    const goDelete = (row) => {
      const data = {};
      data.state = -9;
      if (confirm("나누기를 삭제하시겠습니까?")) {
        api
          .put(`service/class2/sharePractice/${row.row.id}`, { data })
          .then(() => {
            getDataFromApi();
          });
      }
    };
    return {
      tableData, // 레코드셋
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      start1,
      start2,
      end1,
      end2,
      post,
      id,
      share,
      fileName,
      showModal,
      content,
      modules,
      goDelete,
      goShare,
      goEdit,
      onSave,
      getDataFromApi,
      page2,
    };
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
#baseinput {
  margin-left: 10%;
  margin-right: 10%;
}
</style>
